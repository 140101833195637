var exports = {};
exports = kotlin;
kotlin.displayName = "kotlin";
kotlin.aliases = ["kt", "kts"];

function kotlin(Prism) {
  ;

  (function (Prism) {
    Prism.languages.kotlin = Prism.languages.extend("clike", {
      keyword: {
        // The lookbehind prevents wrong highlighting of e.g. kotlin.properties.get
        pattern: /(^|[^.])\b(?:abstract|actual|annotation|as|break|by|catch|class|companion|const|constructor|continue|crossinline|data|do|dynamic|else|enum|expect|external|final|finally|for|fun|get|if|import|in|infix|init|inline|inner|interface|internal|is|lateinit|noinline|null|object|open|operator|out|override|package|private|protected|public|reified|return|sealed|set|super|suspend|tailrec|this|throw|to|try|typealias|val|var|vararg|when|where|while)\b/,
        lookbehind: true
      },
      function: [{
        pattern: /(?:`[^\r\n`]+`|\b\w+)(?=\s*\()/,
        greedy: true
      }, {
        pattern: /(\.)(?:`[^\r\n`]+`|\w+)(?=\s*\{)/,
        lookbehind: true,
        greedy: true
      }],
      number: /\b(?:0[xX][\da-fA-F]+(?:_[\da-fA-F]+)*|0[bB][01]+(?:_[01]+)*|\d+(?:_\d+)*(?:\.\d+(?:_\d+)*)?(?:[eE][+-]?\d+(?:_\d+)*)?[fFL]?)\b/,
      operator: /\+[+=]?|-[-=>]?|==?=?|!(?:!|==?)?|[\/*%<>]=?|[?:]:?|\.\.|&&|\|\||\b(?:and|inv|or|shl|shr|ushr|xor)\b/
    });
    delete Prism.languages.kotlin["class-name"];
    Prism.languages.insertBefore("kotlin", "string", {
      "raw-string": {
        pattern: /("""|''')[\s\S]*?\1/,
        alias: "string" // See interpolation below

      }
    });
    Prism.languages.insertBefore("kotlin", "keyword", {
      annotation: {
        pattern: /\B@(?:\w+:)?(?:[A-Z]\w*|\[[^\]]+\])/,
        alias: "builtin"
      }
    });
    Prism.languages.insertBefore("kotlin", "function", {
      label: {
        pattern: /\b\w+@|@\w+\b/,
        alias: "symbol"
      }
    });
    var interpolation = [{
      pattern: /\$\{[^}]+\}/,
      inside: {
        delimiter: {
          pattern: /^\$\{|\}$/,
          alias: "variable"
        },
        rest: Prism.languages.kotlin
      }
    }, {
      pattern: /\$\w+/,
      alias: "variable"
    }];
    Prism.languages.kotlin["string"].inside = Prism.languages.kotlin["raw-string"].inside = {
      interpolation: interpolation
    };
    Prism.languages.kt = Prism.languages.kotlin;
    Prism.languages.kts = Prism.languages.kotlin;
  })(Prism);
}

export default exports;